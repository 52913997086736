import React, {useMemo} from 'react'
import {useTranslation} from 'next-i18next'
import WizButton from '@component/buttons/WizButton'
import useQueryFetchMe from '@hook/query/account/useQueryFetchMe'
import IconErrorMessage from '@svg/common/ic_error_message.svg'
import useNavigation from '@hook/useNavigation'
import TagText from '@component/Text/TagText'
import useProStatus from '@hook/useProStatus'
import {createLog} from '@util/logs'
import Text from '@component/Text/Text'

type PROWarningType = 'portfolio' | 'copyfolio'

interface IProps {
    className?: string
    exchange?: string
}

const ICON_SIZE = 22
const ProNotice: React.FC<IProps> = ({className, exchange}) => {
    const {t} = useTranslation()
    const {data: me} = useQueryFetchMe(undefined, false)
    const {isExpire, expireDays, proCopyExchange} = useProStatus(me, exchange)

    const containerClassName = useMemo(() => `${className} `, [className])

    const {goAccountProPayment} = useNavigation()

    const portfolioWarningText = useMemo(() => {
        // == 웹 결제 만료 3일전 ==
        // if (me?.pro_method === PROPaymentMethod.crypto && !isExpire && expireDays < 3 && !proCopyExchange) {
        if (!isExpire && expireDays < 3 && !proCopyExchange) {
            return t('portfolioProExpireNotice.type.portfolio.expireInDays.text', {count: expireDays})
        }
        // == 웹 결제 카피 트레이딩 중 && 만료 3일전 ==
        // if (me?.pro_method === PROPaymentMethod.crypto && !isExpire && expireDays < 3 && proCopyExchange) {
        if (!isExpire && expireDays < 3 && proCopyExchange) {
            return t('portfolioProExpireNotice.type.portfolio.expireInDays.with.copy.text', {
                count: expireDays,
                exchange: proCopyExchange,
            })
        }
        // == 카피 트레이딩 중 && 만료 ==
        if (isExpire && proCopyExchange) {
            return t('portfolioProExpireNotice.type.portfolio.expired.text', {exchange: proCopyExchange})
        }
    }, [isExpire, expireDays, proCopyExchange, t])

    const onClickRenewPro = () => {
        createLog('event', 'portfolio_pro_notice_click_pro_renew')
        goAccountProPayment()
    }

    return (
        <>
            {portfolioWarningText && (
                <div className={containerClassName}>
                    <div
                        className={
                            'flex items-center gap-[10px] w-full pb-[10px] border-b border-b-gray07 dark:border-b-dark_gray07 mb-[20px]'
                        }>
                        <div className={'space-y-[5px] flex-1 '}>
                            <div className={'flex items-center gap-x-[5px]'}>
                                <IconErrorMessage
                                    width={ICON_SIZE}
                                    heigth={ICON_SIZE}
                                    className={'fill-red dark:fill-dark_red'}
                                />
                                <Text className={'text-ti2 text-red_shade dark:text-dark_red_shade'}>
                                    {t('portfolioProExpireNotice.type.portfolio.title')}
                                </Text>
                            </div>
                            <TagText className={'text-body3 grow text-gray02 dark:text-dark_gray02'}>
                                {portfolioWarningText}
                            </TagText>
                        </div>
                        <WizButton
                            text={t('portfolioProExpireNotice.type.portfolio.proPayment.renew')}
                            buttonType={'border_red'}
                            size={'small'}
                            onClick={onClickRenewPro}
                        />
                    </div>
                </div>
            )}
        </>
    )
}

export default ProNotice
